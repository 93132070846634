.app-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .app-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .app-link {
    color: #61dafb;
  }
  
  @keyframes app-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }